import { BaseHTMLAttributes, FC, memo } from "react";
import { SectionTitle } from "@/styles/utils/Utils";
import { ROUTES } from "@/utils/constants";
import { Banner } from "components/Banners/Banner";
import { Container } from "styles/utils/StyledGrid";
import { cssBannerTile, SectionAreaActivity, TileBanners, tileBannersCategories, tileBannersProducts } from "./Styled";
type ActivityItemsType = {
  path: string;
  imagePath: string;
};
const areaActivityItems: ActivityItemsType[] = [{
  path: `${ROUTES.catalog}/sredstva-dlya-kuhni`,
  imagePath: "/images/business-areas/img_7.png"
}, {
  path: `${ROUTES.catalog}/meshki-musornye-optom`,
  imagePath: "/images/business-areas/img_6.png"
}, {
  path: `${ROUTES.catalog}/aksessuary-dlya-vannoy-i-tualeta`,
  imagePath: `/images/business-areas/img.png`
}, {
  path: `${ROUTES.catalog}/kasaletki`,
  imagePath: `/images/business-areas/img_5.png`
}, {
  path: `${ROUTES.catalog}/tryapki-salfetki-gubki-terki`,
  imagePath: `/images/business-areas/img_9.png`
}, {
  path: `${ROUTES.catalog}/bumajnye-pakety`,
  imagePath: `/images/business-areas/img_1.png`
}, {
  path: `${ROUTES.catalog}/bumajnye-stakany`,
  imagePath: `/images/business-areas/img_2.png`
}];
const areaActivityItems2: ActivityItemsType[] = [{
  path: `${ROUTES.catalog}/chekovaya-lenta`,
  imagePath: `/images/business-areas/img_3.png`
}, {
  path: `${ROUTES.catalog}/eco-upakovka`,
  imagePath: "/images/business-areas/img_4.png"
}, {
  path: `${ROUTES.catalog}/steklotara-kryshki`,
  imagePath: `/images/business-areas/img_8.png`
}];
type BusinessAreasBannersPropsType = BaseHTMLAttributes<HTMLDivElement>;
const BusinessAreasBanners: FC<BusinessAreasBannersPropsType> = memo(props => {
  return <SectionAreaActivity {...props}>
        <Container>
          <SectionTitle>Сферы деятельности</SectionTitle>
          <TileBanners className={tileBannersCategories}>
            {areaActivityItems.map((item, index) => <Banner key={index} image={{
          desktop: {
            src: item.imagePath,
            layout: "responsive",
            height: 148,
            width: 272,
            objectFit: "contain"
          }
        }} loading="lazy" url={item.path} className={cssBannerTile} withFormatUrl={false} />)}
          </TileBanners>
          <TileBanners className={tileBannersProducts}>
            {areaActivityItems2.map((item, index) => <Banner key={index} image={{
          desktop: {
            src: item.imagePath,
            layout: "responsive",
            height: 290,
            width: 464,
            objectFit: "contain"
          }
        }} loading={"lazy"} url={item.path} className={cssBannerTile} withFormatUrl={false} />)}
          </TileBanners>
        </Container>
      </SectionAreaActivity>;
});
BusinessAreasBanners.displayName = "BusinessAreasBanners";
export type { BusinessAreasBannersPropsType };
export { BusinessAreasBanners };